* {
  color: #ffffff;
}

header {
  z-index: 1;
}

.header_logo {
  font-size: 2rem;
}

.left_panel {
  background: #A8D0E7;
}

.right_panel_top {
  background: #F66C6D;
}

.right_panel_bottom {
  background: #23315D;
}

.footer {
  background: #282C34;
}

/*.mock_image {*/
  /*background: #000;*/
  /*width: 180px;*/
  /*height: 180px;*/
  /*border-radius: 100px;*/
  /*order: 0;*/
/*}*/

.keyword {
  order: 1;
  text-transform: uppercase;
  margin: 0rem 1rem;
}

.keyword:first-letter {
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: underline;
}

.description {
  order: 2;
  font-size: 1.3rem;
  margin: 1rem;
  font-weight: bold;
}

@media all and (max-height: 720px), all and (max-width: 500px) {
  .mock_image {
    margin-top: 48px;
  }

  .row {
    display: block !important;
  }

  i.fas {
    visibility: hidden;
  }
}

i.fas {
  font-size: 10rem;
}

a:hover {
  text-decoration: none;
}